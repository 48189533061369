import {
	List,
	ListItemIcon,
	ListItemText,
	TextField,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { CustomListItemButton } from "common";
import { MdAddAlarm } from "react-icons/md";
import { useNavigate } from "react-location";

const ChooseDestination = () => {
	const navigate = useNavigate();
	return (
		<div>
			<Box
				mt={5}
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}>
				<Typography variant='h5'>Choose destination</Typography>
				<Typography variant='caption' color='GrayText'>
					Open dashboard for selected destination
				</Typography>
				<TextField label='Search...' size='small' />
				<List sx={{ width: "100%", maxWidth: 360 }}>
					<CustomListItemButton
						onClick={() => navigate({ to: "/select-data-source" })}>
						<ListItemIcon>
							<MdAddAlarm />
						</ListItemIcon>
						<ListItemText primary='Destination 1' />
					</CustomListItemButton>

					<CustomListItemButton
						onClick={() => navigate({ to: "/select-data-source" })}>
						<ListItemIcon>
							<MdAddAlarm />
						</ListItemIcon>
						<ListItemText primary='Destination 2' />
					</CustomListItemButton>

					<CustomListItemButton
						onClick={() => navigate({ to: "/select-data-source" })}>
						<ListItemIcon>
							<MdAddAlarm />
						</ListItemIcon>
						<ListItemText primary='Destination 3' />
					</CustomListItemButton>
				</List>
			</Box>
		</div>
	);
};

export default ChooseDestination;
