export const DATA_SOURCE_LIST = {
	first_source_type: [
		{
			id: "first_source_type_A",
			icon: "/route-1",
			title: "First Source Type A",
			description: "description text",
		},
		{
			id: "first_source_type_B",
			icon: "/route-1",
			title: "First Source Type B",
			description: "description text",
		},
		{
			id: "first_source_type_C",
			icon: "/route-1",
			title: "First Source Type C",
			description: "description text",
		},
	],

	second_source_type: [
		{
			id: "second_source_type_X",
			icon: "/route-1",
			title: "Second Source Type X",
			description: "description text",
		},
		{
			id: "second_source_type_Y",
			icon: "/route-1",
			title: "Second Source Type Y",
			description: "description text",
		},
		{
			id: "second_source_type_Z",
			icon: "/route-1",
			title: "Second Source Type Z",
			description: "description text",
		},
	],
	third_source_type: [
		{
			id: "third_source_typeE",
			icon: "/route-1",
			title: "Third Source Type E",
			description: "description text",
		},
		{
			id: "third_source_typeF",
			icon: "/route-1",
			title: "Third Source Type F",
			description: "description text",
		},
		{
			id: "third_source_typeG",
			icon: "/route-1",
			title: "Third Source Type G",
			description: "description text",
		},
	],
	fourth_source_type: [
		{
			id: "fourth_source_type_L",
			icon: "/route-1",
			title: "Fourth Source Type L",
			description: "description text",
		},
		{
			id: "fourth_source_type_M",
			icon: "/route-1",
			title: "Fourth Source Type M",
			description: "description text",
		},
		{
			id: "fourth_source_type_N",
			icon: "/route-1",
			title: "Fourth Source Type N",
			description: "description text",
		},
	],
};
