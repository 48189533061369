import {
	AppBar,
	Avatar,
	Box,
	Grid,
	Link,
	Toolbar,
	Typography,
} from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useLocation } from "react-location";
import AppBarMenu from "./AppBarMenu";

/* this component should be render on top of every page when user is authenticated */
const Header = () => {
	const location = useLocation();

	return (
		<AppBar
			sx={{ backgroundColor: "hsl(220, 10%, 94%)" }}
			position='static'
			elevation={0}>
			<Toolbar>
				<Box display={"flex"} flexGrow={1}>
					{location.current.pathname === "/choose-destination" ? (
						<Link href='#' underline='none'>
							Go to account <MdKeyboardArrowRight />
						</Link>
					) : (
						<AppBarMenu />
					)}
				</Box>

				<Box>
					<Grid container>
						<Grid item xs={2}>
							<Avatar>B</Avatar>
						</Grid>
						<Grid item container xs={4} justifyContent={"flex-end"}>
							<Grid item xs={12}>
								<Typography color='black'>Full username</Typography>
							</Grid>
							<Grid item xs={12}>
								<Link href='#' underline='hover'>
									Logout
								</Link>
								&nbsp;·&nbsp;
								<Link href='#' underline='hover'>
									Support
								</Link>
								&nbsp;·&nbsp;
								<Link href='#' underline='hover'>
									Privacy
								</Link>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
