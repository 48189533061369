import { ThemeProvider } from "@mui/material/styles";
import Header from "components/navigation/Header";
import Navigation from "components/navigation/Navigation";
import { Outlet, useLocation } from "react-location";
import { useAppSelector } from "store";
import { appDataInReduxStore } from "store/app/appSlice";
import { darkTheme, lightTheme } from "theme/Theme";

const App = () => {
	const location = useLocation();
	const { isDarkModeActive } = useAppSelector(appDataInReduxStore);
	return (
		<ThemeProvider theme={isDarkModeActive ? darkTheme : lightTheme}>
			{location.current.pathname === "/" ||
			location.current.pathname === "/login" ? (
				<Navigation />
			) : (
				<Header />
			)}

			<Outlet />
		</ThemeProvider>
	);
};

export default App;
