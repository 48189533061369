/*!!! OFFICIAL WAY OF PASSING PROPS FROM COMPONENT TO STYLED OBJ */
// import { ListItemButton } from "@mui/material";
// import { styled } from "@mui/material/styles";

// const CustomListItemButton = styled(ListItemButton, {
// 	shouldForwardProp: (prop) => prop !== "color",
// })<{ color?: string }>(({ color }) => ({
// 	backgroundColor: color ? color : "antiquewhite",
// 	margin: 8,
// 	borderRadius: "10px",
// 	"&: hover": {
// 		backgroundColor: "thistle",
// 	},
// }));

// export default CustomListItemButton;

/* ALTERNATE WAY OF PASSING PROPS FROM COMPONENT TO STYLED OBJ */
import { ListItemButton } from "@mui/material";
import { styled } from "@mui/material/styles";

type ListItemButtonType = { color?: string };

const CustomListItemButton = styled(ListItemButton)<ListItemButtonType>(
	({ color }) => ({
		backgroundColor: color ? color : "antiquewhite",
		margin: 8,
		borderRadius: "10px",
		"&: hover": {
			backgroundColor: "thistle",
		},
	})
);

export default CustomListItemButton;
