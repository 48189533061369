import { ListItemIcon, ListItemText } from "@mui/material";

type CustomListItemTypes = {
	icon: React.ReactElement;
	primaryText: string;
	secondaryText?: string;
};

const CustomListItem = (props: CustomListItemTypes) => (
	<>
		<ListItemIcon>{props.icon}</ListItemIcon>
		<ListItemText primary={props.primaryText} secondary={props.secondaryText} />
	</>
);

export default CustomListItem;
