// export const DATA_SOURCES = [
// 	"all_data_sources",
// 	"first_source_type",
// 	"second_source_type",
// 	"third_source_type",
// 	"fourth_source_type",
// ];
export const DATA_SOURCES = {
	all_data_sources: {
		id: "all_data_sources",
		icon: "icon",
		title: "All Data Sources",
	},
	first_source_type: {
		id: "first_source_type",
		icon: "icon",
		title: "First Source Type",
	},
	second_source_type: {
		id: "second_source_type",
		icon: "icon",
		title: "Second Source Type",
	},
	third_source_type: {
		id: "third_source_type",
		icon: "icon",
		title: "Third Source Type",
	},
	fourth_source_type: {
		id: "fourth_source_type",
		icon: "icon",
		title: "Fourth Source Type",
	},
};
