import {
	Button,
	Container,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Radio,
	RadioGroup,
} from "@mui/material";
import { FormikControl, ToggleSwitch } from "common";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-location";
import * as yup from "yup";

const ConfigForm = () => {
	const navigate = useNavigate();

	const initialValues = {
		dataProcessingLocation: "",
		cloudServiceProvider: "",
	};
	const validationSchema = yup.object({});

	const onSubmit = (values: {}) => {
		console.log("Form data", values);
		navigate({ to: "/choose-destination" });
	};

	const [value, setValue] = useState("direct");

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue((event.target as HTMLInputElement).value);
	};

	const dropdownOptions = [
		{ key: "Select an option", value: "" },
		{ key: "Option 1", value: "option1" },
		{ key: "Option 2", value: "option2" },
		{ key: "Option 3", value: "option3" },
	];

	return (
		<Container maxWidth='xs'>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}>
				{(formik) => (
					<Form>
						<FormikControl
							control='muiInput'
							label='Destination Schema Prefix'
							name='destinationSchemaPrefix'
						/>

						<FormikControl control='muiInput' label='Host' name='host' />
						<FormikControl control='muiInput' label='Port' name='port' />
						<FormikControl control='muiInput' label='User' name='user' />
						<FormikControl
							control='muiInput'
							label='Password'
							name='password'
						/>

						<FormControl component='fieldset'>
							<FormLabel component='legend'>Connection Method</FormLabel>
							<RadioGroup
								aria-label='gender'
								name='controlled-radio-buttons-group'
								value={value}
								onChange={handleChange}>
								<FormControlLabel
									value='direct'
									control={<Radio />}
									label='Connect Directly'
								/>
								<FormControlLabel
									value='ssh'
									control={<Radio />}
									label='Connect via an SSH tunnel'
								/>
							</RadioGroup>
						</FormControl>
						{/*  this will render when user wants to connect via ssh tunnel */}
						{value === "ssh" ? (
							<>
								<FormikControl
									control='muiInput'
									label='SSH Host'
									name='sshHost'
								/>
								<FormikControl
									control='muiInput'
									label='SSH Port'
									name='sshPort'
								/>
								<FormikControl
									control='muiInput'
									label='SSH User'
									name='sshUser'
								/>
								<FormikControl
									control='muiInput'
									label='Public Key'
									name='publicKey'
								/>
								<FormGroup>
									<FormControlLabel
										control={<ToggleSwitch defaultChecked />}
										label='Require TLS through tunnel'
										labelPlacement='start'
									/>
								</FormGroup>
							</>
						) : null}

						<FormikControl
							control='muiInput'
							label='Replica Id'
							name='replicaId'
						/>

						<FormikControl
							control='muiSelect'
							label='Data Processing Location'
							name='dataProcessingLocation'
							options={dropdownOptions}
						/>
						<FormikControl
							control='muiSelect'
							label='Cloud Service Provider'
							name='cloudServiceProvider'
							options={dropdownOptions}
						/>

						<Button type='submit' variant='contained' fullWidth>
							Save and Test
						</Button>
					</Form>
				)}
			</Formik>

			<br />
		</Container>
	);
};
export default ConfigForm;
