import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-location";

const Navigation = () => {
	const location = useLocation();
	const navigate = useNavigate();
	return (
		<AppBar position='static' sx={{ backgroundColor: "white" }} elevation={0}>
			<Toolbar variant='dense'>
				<Typography
					variant='h6'
					component='div'
					sx={{ flexGrow: 1 }}
					color='GrayText'>
					Data Platform
				</Typography>
				{location.current.pathname !== "/login" ? (
					<Button color='secondary' onClick={() => navigate({ to: "/login" })}>
						Log in
					</Button>
				) : null}
			</Toolbar>
		</AppBar>
	);
};

export default Navigation;
