import { Grid } from "@mui/material";
import ConfigForm from "components/configForm/ConfigForm";
import ConfigSteps from "components/configSteps/ConfigSteps";

const FormPage = () => {
	return (
		<Grid container justifyContent={"center"}>
			<Grid item xs={5}>
				<ConfigForm />
			</Grid>
			<Grid item xs={6}>
				<ConfigSteps />
			</Grid>
		</Grid>
	);
};

export default FormPage;
