import { Box, Button, AppBar, Toolbar, Container, Stack } from "@mui/material";
import SelectDataSource from "components/selectDataSource/SelectDataSource";
import { MdArrowForward } from "react-icons/md";
import { useNavigate } from "react-location";

const SelectDataSourcePage = () => {
	const navigate = useNavigate();
	return (
		<>
			<SelectDataSource />
			{/* <Box display='flex' justifyContent='center' mt={2}> */}
			<AppBar position='fixed' color='inherit' sx={{ top: "auto", bottom: 0 }}>
				<Toolbar>
					<Container maxWidth='md'>
						<Box display={"flex"} justifyContent={"flex-end"}>
							<Stack direction='row' spacing={2}>
								<Button variant='outlined'>Cancel</Button>
								<Button
									variant='contained'
									onClick={() => navigate({ to: "/form" })}
									endIcon={<MdArrowForward />}>
									Continue Setup
								</Button>
							</Stack>
						</Box>
					</Container>
				</Toolbar>
			</AppBar>

			{/* </Box> */}
		</>
	);
};

export default SelectDataSourcePage;
