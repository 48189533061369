import MuiCheckbox from "./MuiCheckbox";
import MuiInput from "./MuiInput";
import MuiSelect from "./MuiSelect";
import MuiTextArea from "./MuiTextArea";

type FormikControlType = {
	control: string;
	label: string;
	name: string;
	type?: string;
	options?: Array<{ key: string; value: string }>;
};

const FormikControl = (props: FormikControlType) => {
	const { control, ...rest } = props;
	switch (control) {
		case "muiInput":
			return <MuiInput {...rest} />;
		case "muiSelect":
			return <MuiSelect {...rest} />;
		case "muiCheckbox":
			return <MuiCheckbox {...rest} />;
		case "muiTextArea":
			return <MuiTextArea {...rest} />;

		default:
			return null;
	}
};

export default FormikControl;
