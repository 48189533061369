import {
	Box,
	Grid,
	InputAdornment,
	List,
	TextField,
	Typography,
} from "@mui/material";
import { CustomListItem, CustomListItemButton } from "common";
import { DATA_SOURCE_LIST } from "constants/DataSourceList";
import { DATA_SOURCES } from "constants/DataSources";
import { useState } from "react";
import { MdAddAlarm, MdSearch } from "react-icons/md";

const SelectDataSource = () => {
	const [selectedDataSource, setSelectedDataSource] =
		useState("all_data_sources");

	return (
		<Box mt={6}>
			<Box mb={2}>
				<Grid container alignItems='center' justifyContent='center' spacing={1}>
					<Grid item xs={2}>
						<Typography align='center' variant='h6'>
							Select your data source
						</Typography>
					</Grid>
					<Grid item xs={5}>
						<TextField
							size='small'
							placeholder='Search all sources...'
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<MdSearch />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
			</Box>

			<Grid container justifyContent='center' spacing={1}>
				{/* list showing type of data source eg: databases,analytics etc */}
				<Grid item xs={2}>
					<List dense>
						{Object.values(DATA_SOURCES)?.map((data) => (
							<CustomListItemButton
								color='darkseagreen'
								key={data.id}
								onClick={() => setSelectedDataSource(data.id)}>
								<CustomListItem
									icon={<MdAddAlarm />}
									primaryText={data.title}
								/>
							</CustomListItemButton>
						))}
					</List>
				</Grid>
				{/* list showing actual name of data source*/}
				<Grid item xs={5} sx={{ height: "400px", overflow: "auto" }}>
					{selectedDataSource !== "all_data_sources" ? (
						<List dense>
							{DATA_SOURCE_LIST[
								selectedDataSource as keyof typeof DATA_SOURCE_LIST
							]?.map((data) => (
								<CustomListItemButton key={data.id}>
									<CustomListItem
										icon={<MdAddAlarm />}
										primaryText={data.title}
										secondaryText={data.description}
									/>
								</CustomListItemButton>
							))}
						</List>
					) : (
						<List dense>
							{Object.values(DATA_SOURCE_LIST)?.map((data) =>
								data.map((finalData) => (
									<CustomListItemButton key={finalData.id}>
										<CustomListItem
											icon={<MdAddAlarm />}
											primaryText={finalData.title}
											secondaryText={finalData.description}
										/>
									</CustomListItemButton>
								))
							)}
						</List>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default SelectDataSource;
