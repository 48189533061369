import ChooseDestinationPage from "pages/chooseDestinationPage/ChooseDestinationPage";
import FormPage from "pages/formPage/FormPage";
import HomePage from "pages/homePage/HomePage";
import LoginPage from "pages/loginPage/LoginPage";
import SelectDataDestinationPage from "pages/selectDataDestinationPage/SelectDataDestinationPage";
import SelectDataSourcePage from "pages/selectDataSourcePage/SelectDataSourcePage";
import { Navigate, Route } from "react-location";

const authenticated = true;

export const appRoutes: Route[] = [
	{
		path: "/",
		element: <HomePage />,
	},
	{
		path: "/login",
		element: <LoginPage />,
	},
	{
		path: "/select-data-source",
		element: <SelectDataSourcePage />,
	},
	{
		path: "/select-data-destination",
		element: <SelectDataDestinationPage />,
	},
	{
		path: "/choose-destination",
		element: <ChooseDestinationPage />,
	},
	{
		path: "/form",
		element: <FormPage />,
	},

	{
		path: "/dashboard",
		element: authenticated ? (
			<h1>You will only see this if you are authenticated</h1>
		) : (
			<h1>
				You will need to turn value of authenticated to <b>true</b> in
				Approutes.tsx file to access this route{" "}
			</h1>
		),
	},
	{
		element: <Navigate to='/' />,
	},
];
