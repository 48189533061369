import { Box, MenuItem, TextField } from "@mui/material";
import { Field, FieldProps } from "formik";

type MuiSelectProps = {
	label: string;
	name: string;
	options?: Array<{ key: string; value: string }>;
};

function MuiSelect(props: MuiSelectProps) {
	const { label, name, options, ...rest } = props;

	return (
		<Field name={name}>
			{({ field, form }: FieldProps) => (
				<Box mt={1} mb={1}>
					<TextField
						select
						fullWidth
						size='small'
						variant='filled'
						id={name}
						label={label}
						{...rest}
						{...field}
						error={form.errors[name] && form.touched[name] ? true : false}
						helperText={
							form.errors[name] && form.touched[name] ? form.errors[name] : null
						}>
						{options?.map((option) => {
							return (
								<MenuItem key={option.key} value={option.value}>
									{option.key}
								</MenuItem>
							);
						})}
					</TextField>
				</Box>
			)}
		</Field>
	);
}

export default MuiSelect;
