import { Box, TextField } from "@mui/material";
import { Field, FieldProps } from "formik";

type MuiTextAreaProps = {
	label: string;
	name: string;
};

const MuiTextArea = (props: MuiTextAreaProps) => {
	const { label, name, ...rest } = props;
	return (
		<Field name={name}>
			{({ field, form }: FieldProps) => (
				<Box mt={1} mb={1}>
					<TextField
						id={name}
						size='small'
						variant='outlined'
						fullWidth
						multiline
						rows={3}
						label={label}
						error={form.errors[name] && form.touched[name] ? true : false}
						helperText={
							form.errors[name] && form.touched[name] ? form.errors[name] : null
						}
						{...rest}
						{...field}
					/>
				</Box>
			)}
		</Field>
	);
};

export default MuiTextArea;
