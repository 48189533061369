import {
	Button,
	Card,
	Container,
	Divider,
	Link,
	Typography,
} from "@mui/material";
import { FormikControl } from "common";
import { Form, Formik } from "formik";
import { useNavigate } from "react-location";
import * as yup from "yup";

const LoginPage = () => {
	const navigate = useNavigate();

	const initialValues = {
		email: "",
		password: "",
	};
	const validationSchema = yup.object({
		email: yup.string().required("Required"),
		password: yup.string().required("Required"),
	});

	const onSubmit = (values: {}) => {
		console.log("Form data", values);
		navigate({ to: "/choose-destination" });
	};

	return (
		<Container maxWidth='xs'>
			<Card sx={{ p: 4, mt: 4 }}>
				<Typography variant='h4' align='center'>
					Welcome Back
				</Typography>
				<Typography variant='subtitle1' color='GrayText' align='center'>
					Sign In to continue{" "}
				</Typography>

				<Button variant='contained' fullWidth>
					Google Sign In
				</Button>
				<Divider>OR</Divider>

				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}>
					{(formik) => (
						<Form>
							<FormikControl control='muiInput' label='Email' name='email' />

							<FormikControl
								control='muiInput'
								label='Password'
								name='password'
							/>
							<br />
							<Button type='submit' variant='contained' fullWidth>
								Sign In
							</Button>
						</Form>
					)}
				</Formik>

				<br />
				<Link underline='none' href='#'>
					Forgot your password?
				</Link>
			</Card>
		</Container>
	);
};
export default LoginPage;
